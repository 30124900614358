import { useEffect } from "react";


function Screensaver({setScreenSaver}) {

    useEffect(() => {

        /*const interval = setInterval(() => {
            alert('alertti');
        }, 5000);

        return () => {
            clearInterval(interval);
        }*/
    }, []);
    return (
        <div className="ScreenSaver" onClick={() => setScreenSaver(false)}>
            <img src={require('./images/screensaver.png')} width='100%' height='100%' />
        </div>
    )
}
export default Screensaver;
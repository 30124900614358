
import chevron from './images/chevron.svg';

import floor0 from './images/kerros0.jpg';
import floor1 from './images/kerros1.jpg';
import floor2 from './images/kerros2.jpg';

function Content({floor, setFloor}) {

    function addFloor() {

        if(floor === 2) {
            return;
        }
        else {
            setFloor(floor+1);
        }
    }
    function reduceFloor() {
        if(floor === 0 ) {
            return;
        }
        else {
            setFloor(floor-1);
        }
    }
    return (
        <>
        <div className="Content">
            <div className="FloorSelector">
                <ul>
                    <li><img src={chevron} style={{width: 36, transform: 'rotate(-90deg)', marginBottom: 12, cursor: 'pointer'}} onClick={addFloor} /></li>
                    <li onClick={() => setFloor(2)}>Kerros 2</li>
                    <li onClick={() => setFloor(1)}>Kerros 1</li>
                    <li onClick={() => setFloor(0)}>Kerros 0</li>
                    <li><img src={chevron} style={{width: 36, transform: 'rotate(90deg)', marginTop: 12, cursor: 'pointer'}} onClick={reduceFloor} /></li>
                </ul>
            </div>
            <div className='Floor'>
                <img src={
                    floor === 0 ? floor0
                    : floor === 1 ? floor1
                    : floor === 2 ? floor2
                    : floor1
                }
                style={{width: '100%',position: 'absolute', top: '50%', left: 0, transform: 'translateY(-50%) rotate(90deg)'}}
                />
            </div>
        </div>
        </>
    )
}

export default Content


function Bottom({setTitle, setFilters}) {
    return (
        <div className="Bottom">
            <div className="BottomNav">
                <ul className="Buttons">
                    <li><button onClick={() => setTitle('Ravintolat ja kahvilat')}>Ravintolat ja kahvilat</button></li>
                    <li><button onClick={() => setTitle('Päivittäistavarat')}>Päivittäistavarat</button></li>
                    <li><button onClick={() => setTitle('Kauneus, terveys ja hyvinvointi')}>Kauneus, terveys ja hyvinvointi</button></li>
                    <li><button onClick={() => setTitle('Vapaa-aika ja harrastukset')}>Vapaa-aika ja harrastukset</button></li>
                    <li><button onClick={() => setTitle('Koti, sisustus ja lahjatavarat')}>Koti, sisustus ja lahjatavarat</button></li>
                    <li><button onClick={() => setTitle('Muut palvelut')}>Muut palvelut</button></li>
                </ul>
            </div>
            <div className="BottomBackground">
                <div className="BottomBackgroundFill"></div>
            </div>
        </div>
    )
}
export default Bottom;
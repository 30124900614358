


function Top() {
    return (
        <div className="Top">
            <div className="MainLogo">
                <img src={require('./images/gmlogo.png')} />
            </div>
        </div>
    )
}
export default Top;
import { useEffect, useState } from "react";


function Nav({setActiveShop, title, setTitle}) {
    

    const [filteredData, setFilteredData] = useState([]);
    const data = [
        {
        category: 'ravintolat-ja-kahvilat',
        store: 'Aito Sushi',
        openings: 'ma-pe: 10-21, la: 10:30-21, su: 11-20'
        },
        {
        category: 'ravintolat-ja-kahvilat',
        store: 'Bobo',
        openings: 'ma-pe: 10-19, la: 11-19, su: suljettu'
        },
        {
        category: 'ravintolat-ja-kahvilat',
        store: 'Hesburger',
        openings: 'ma-pe: 09-20, la: 10-19, su: 12-18'
        },
        {
        category: 'ravintolat-ja-kahvilat',
        store: 'Pancho Villa',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'paivittaistavarat',
        store: 'Alko',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'paivittaistavarat',
        store: 'Clas ohlson',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'paivittaistavarat',
        store: 'Normal',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'paivittaistavarat',
        store: 'S-market',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'vapaa-aika-ja-harrastukset',
        store: 'DNA',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'vapaa-aika-ja-harrastukset',
        store: 'Elisa',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'koti-sisustus-ja-lahjatavarat',
        store: 'Clas Ohlson',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'koti-sisustus-ja-lahjatavarat',
        store: 'Finlayson',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'koti-sisustus-ja-lahjatavarat',
        store: 'Flying Tiger cöpenhagen',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'koti-sisustus-ja-lahjatavarat',
        store: 'Finnmari',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'kauneus-terveys-hyvinvointi',
        store: 'Emotion',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'kauneus-terveys-hyvinvointi',
        store: 'Life',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'kauneus-terveys-hyvinvointi',
        store: 'Nissen',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        },
        {
        category: 'kauneus-terveys-hyvinvointi',
        store: 'Normal',
        openings: 'ma-pe: 11-19, la: 11-18, su: 12-16'
        }
    ]

    useEffect(() => {

        setFilteredData(data);
    }, []);
    return (
        <div className="Nav">
            <h1 style={{color: 'white', fontSize: 32, marginTop: 20}}>{title}</h1>
            <div className="separator"></div>
            <ul>
            {
            filteredData.map((item, index) => 
                <li style={{marginTop: 20, marginBottom: 20}}>
                <p className="store" style={{marginTop: 6, marginBottom: 12}}>{item.store}</p>
                <p className="openings" style={{marginBottom: 6}}>{item.openings}</p>
                </li>
            )}
            </ul>
        </div>
    )
}
export default Nav;
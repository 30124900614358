

import Top from './Top';
import Content from './Content';
import Nav from './Nav';
import Bottom from './Bottom';
import { useState } from 'react';
function Screen() {

    const [title, setTitle] = useState('Kaikki liikkeet');
    const [floor, setFloor] = useState(1);
    const [activeShop, setActiveShop] = useState('');
    return (
        <div className="ScreenContent">
            <Top />
            <Content floor={floor} setFloor={setFloor} />
            <Nav activeShop={activeShop} setActiveShop={setActiveShop} title={title} setTitle={setTitle} />
            <Bottom setTitle={setTitle} />
        </div>
    )
}

export default Screen;

import { useState } from 'react';
import './App.css';

import Screensaver from './components/Screensaver';
import Screen from './components/Screen';

function App() {
  
  // Triggeröidäänkö screensaver
  const [screenSaver, setScreenSaver] = useState(true);
  
  return (
    <div className="App">
      <div className='Screen'>
      {
        screenSaver === true ? 
          <Screensaver setScreenSaver={setScreenSaver} />
        :
          <Screen setScreenSaver={setScreenSaver} />
      }
      </div>
    </div>
  );
}

export default App;
